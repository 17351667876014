import { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../ProgressWrapper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { parseISO, format } from 'date-fns';
import { faStar, faLink, faClipboard } from '@fortawesome/pro-light-svg-icons';
import { faStar as solidStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import TagDisplay from '../tags/TagDisplay';
import { InputSwitch } from 'primereact/inputswitch';

export default function FlagDetails({ visible, hide, flag, flipFetchFlag, apiKey, toggleFlag }) {
    const [ loading, setLoading ] = useState(true);
    const [ copying, setCopying ] = useState(false);
    const [ history, setHistory ] = useState([]);
    const [ curlString, setCurlString ] = useState();
    const [ linkString, setLinkString ] = useState();
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const toast = useRef(null);

    const toggleFavorite = () => {
        flag.favorite = !flag.favorite;
        axiosInstance.current.post("/flags", flag).finally(() => hide());
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(flag.flagId);
        toast.current.show({ severity: 'info', detail: 'Copied!'});
    }
    
    const copyCurlToClipboard = () => {
        navigator.clipboard.writeText(curlString);
        toast.current.show({ severity: 'info', detail: 'Copied!'});
    }

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(linkString);
        toast.current.show({ severity: 'info', detail: 'Copied!'});
    }

    const copyToAllEnvironments = () => {
        setCopying(true);
        axiosInstance.current.put("/flags/" + flag?.flagId + "/copy").then(() => {
            toast.current.show({ severity: 'success', detail: 'Copied to other environments!'});
        })
        .catch(() => {
            toast.current.show({ severity: 'error', detail: 'Unable to copy to other environments.'});
        })
        .finally(() => {
            setCopying(false);
            flipFetchFlag();
        });
    }

    useEffect(() => {
        if (!flag) return;
        axiosInstance.current.get("/flags/" + flag?.id + "/history").then((response) => {
            response.data.forEach(h => {
                h.displayTime = format(parseISO(h.eventTime), "MMMM dd, yyyy h:mm a");
            })
            setHistory(response.data);
        })
        .finally(() => {
            setCurlString(`curl --location "https://www.featurerollout.com/api/flags/status/environment/${flag?.environmentId}/flag/${flag?.flagId}" \
                            --header "api-key: ${apiKey}"`);
            setLinkString(`https://www.featurerollout.com/projects/${flag.projectId}/e/${flag.environmentId}/f/${flag.flagId}`);
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flag])

    return (
        <Sidebar visible={visible} onHide={hide} position="right" className="p-sidebar-md">
            <Toast ref={toast} />
            <div className="flex-row title">
            <div>
                {flag?.name}
            </div>
            <div className="favorite-wrapper pl20" onClick={() => toggleFavorite()}>
                {flag?.favorite ? 
                <FontAwesomeIcon icon={solidStar} className="favorite" />
                :
                <FontAwesomeIcon icon={faStar} className="non-favorite" />
                }
            </div>
            </div>
            <div className="secondary-title pt20">
                Environment: {flag?.environmentName}
            </div>
            <div className="secondary-title pt20 pointer" onClick={() => navigate("/analytics/" + flag.flagId)}>
                <FontAwesomeIcon icon={faLink} /> View Analytics
            </div>
            <Button style={{ marginTop: 20 }} className="p-button-outlined" icon="pi pi-clone" label="Copy to All Environments" loading={copying} onClick={copyToAllEnvironments} />
            <div className="mt20">
                <div className="secondary-title">
                    Flag ID<FontAwesomeIcon icon={faClipboard} style={{ marginLeft: 20, cursor: 'pointer', fontSize: 16 }} onClick={copyToClipboard} />
                </div>
                <div className="audit-details">
                    {flag?.flagId}
                </div>
            </div>
            <div className="mt20">
                <div className="secondary-title">
                    Link<FontAwesomeIcon icon={faClipboard} style={{ marginLeft: 20, cursor: 'pointer', fontSize: 16 }} onClick={copyLinkToClipboard} />
                </div>
                <div className="audit-details">
                    {linkString}
                </div>

            </div>
            {flag && flag.enabled ?
                <div style={{ color: 'var(--tertiary-color)', fontSize: '1.5em' }}>Enabled</div>
            :
                <div style={{ color: 'black', fontSize: '1.5em' }}>Disabled</div>
            }
            <InputSwitch checked={flag?.enabled} onChange={() => toggleFlag(flag)} />
            <div className="secondary-title mt20">
                Audience: {(flag?.requests * 100).toFixed(2)}%
            </div>
            <TagDisplay tags={flag?.tags} />
            {loading ? 
                <ProgressWrapper />
            :
                <>
                <div className="secondary-title mt20">Flag History</div>
                <DataTable
                    value={history}
                    rows={5}
                    paginator
                    responsiveLayout="stack"
                    size="small"
                    dataKey="historyId"
                >
                    <Column field="event" header="Event"></Column>
                    <Column field="displayTime" header="Event Time"></Column>
                    <Column field="user" header="By"></Column>
                </DataTable>

                <div className="secondary-title">Usage<FontAwesomeIcon icon={faClipboard} style={{ marginLeft: 20, cursor: 'pointer', fontSize: 16 }} onClick={copyCurlToClipboard} /></div>
                    <div className="audit-details">
                        {curlString}
                    </div>
                </>
            }
        </Sidebar>
    )
}
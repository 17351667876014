import { useState } from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

export default function CreateEditProject({ visible, hide, displayToast, project}) {
    const [ saving, setSaving ] = useState(false);
    // eslint-disable-next-line
    const [ formData, setFormData ] = useState({});
    const axiosInstance = useAxios();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: project ? project.name : ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required';
            }
            return errors;
        },
        onSubmit: (data) => {
            setSaving(true);
            setFormData(data);
            axiosInstance.current.post("/projects", data).then((response) => {
                if (project) {
                    displayToast({ severity: 'success', summary: 'Saved!', detail: 'Project saved successfully!'});
                } else {
                    displayToast({ severity: 'success', summary: 'Created!', detail: 'Project created successfully!'});
                }
            }).catch((err) => {
                console.log(err);
                displayToast({ severity: 'error', summary: 'Error!', detail: 'Error encountered!'});
            }).finally(() => {
                setSaving(false);
                hide();
            })
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    }

    return (
        <Dialog header={project ? "Edit Project" : "Create New Project"} visible={visible} onHide={hide}>
            <div className="flex-col center">
                <div className="card">
                    <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                        <div className="field m20">
                            <span className="p-float-label">
                                <InputText id="name" name="name" maxLength={100} value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('text') })} />
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Project Name *</label>
                            </span>
                            {getFormErrorMessage('name')}
                        </div>

                        <div className="flex-row center mt20">
                            <Button style={{ margin: 20 }}type="submit" label={project ? "Save Project" : "Create Project"} icon="pi pi-save" loading={saving} />
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}
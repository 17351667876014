import { useKeycloak } from "@react-keycloak/web"
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPennant, faCogs, faFileInvoice, faAlbumCollection } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import { Image } from 'primereact/image';

export default function Sidebar() {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    return (
        <div id="sidebar">
            <div>
                <div className="flex-col align-center pointer" onClick={() => navigate('/')}>
                    <div style={{ fontSize: 40 }}>
                        Featurely
                    </div>
                    <div style={{ fontSize: 10 }}>
                        By New Labs
                    </div>
                    <Image src="/flag.png" width={100}/>
                </div>
                <div>
                    <div className="sidebar-link pointer" style={{ fontSize: 28 }} onClick={() => navigate("/projects")}>
                        <FontAwesomeIcon icon={faAlbumCollection} style={{ marginRight: 10, fontSize: 20 }} />Projects
                    </div>
                    <div className="sidebar-link pointer" style={{ fontSize: 28 }} onClick={() => navigate("/flags")}>
                        <FontAwesomeIcon icon={faPennant} style={{ marginRight: 10, fontSize: 20 }} />Flags
                    </div>
                    <div className="sidebar-link pointer" style={{ fontSize: 28}} onClick={() => navigate("/documentation")}>
                        <FontAwesomeIcon icon={faFileInvoice} style={{ marginRight: 10, fontSize: 20 }} />How-To Guides
                    </div>
                    <div className="sidebar-link pointer" style={{ fontSize: 28 }} onClick={() => navigate("/about")}>
                        <FontAwesomeIcon icon={faCogs} style={{ marginRight: 10, fontSize: 20 }} />About
                    </div>
                    <Divider />
                </div>
            </div>
            <div>
                <div>
                    <div className="audit-details">
                        <div>Version: {window.VERSION} Build: {window.BUILD}</div>
                        <div>Need support? admin@new-labs.co</div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <div className="audit-details">New Labs System Status</div>
                        <statuspage-widget src="https://newlabs.statuspage.io"></statuspage-widget>
                    </div>
                    
                    <div className="mt10">
                        <Button className="p-button-warning" icon="pi pi-sign-out" label="Logout" onClick={() => keycloak.logout()} />
                    </div>
                </div>
            </div>
        </div>
    )
}
import { Chip } from 'primereact/chip';

export default function TagDisplay({ tags, selectTag, removable }) {
    return (
        <div>
            {tags.map(( tag, index ) => (
                <Chip key={index} label={tag.text} style={{ margin: 2, backgroundColor: '#3498db', color: 'white' }} removable={removable} onRemove={(e) => {
                    if (typeof selectTag !== 'undefined') {
                        selectTag(tag, e)
                    }
                }}/>
            ))}
        </div>
    )
}
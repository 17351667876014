import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../../components/ProgressWrapper';

export default function Join() {
    let { id } = useParams();
    const navigate = useNavigate();
    const axiosInstance = useAxios();

    React.useEffect(() => {
        axiosInstance.current.put("/projects/join/" + id)
        .catch((err) => {
            console.error(err)
        })
        .finally(() => {
            navigate("/");
        })
    })

    return (
        <>
            <ProgressWrapper />
        </>
    )
}
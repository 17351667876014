import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils/hooks.ts';
import { faStar, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { faStar as solidStar } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { confirmPopup } from 'primereact/confirmpopup';
import InviteComponent from '../../components/projects/Invite';
import ManageUsers from '../../components/projects/ManageUsers';

export default function ProjectOptions({ displayToast, project, toggleEditProjectVisible }) {
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ inviteVisible, setInviteVisible ] = React.useState(false);
    const [ manageUsersVisible, setManageUsersVisible ] = React.useState(false);
    const { id } = useParams();
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);
    const op = React.useRef(null);

    const toggleProjectFavorite = () => {
        project.favorite = !project.favorite;
        axiosInstance.current.post("/projects", project).then((response) => {
            flipFetchFlag();
        })
        .catch((err) => {
            displayToast({ severity: 'error', summary: 'Error!'});
        })
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleInvite = () => {
        setInviteVisible(!inviteVisible);
    }

    const toggleManageUsers = () => {
        setManageUsersVisible(!manageUsersVisible);
    }

    const inviteSent = (success) => {
        if (success) {
            displayToast({severity: 'success', summary: 'Sent!', detail: 'Invite sent!'});
        } else {
            displayToast({severity: 'error', summary: 'Error!', detail: 'Error while sending invite!'})
        }
        toggleInvite();
    }

    const accept = () => {
        axiosInstance.current.delete("/projects/" + id)
            .then(() => {
                navigate("/flags");
            })
            .catch(() => {
                toast.current.show({severity: 'error', summary: 'Error!', details: 'Unable to delete project'});
            })
    };

    const reject = () => {
    };

    const confirm = (e) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this project?",
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        })
    }

    return (
        <>
        <InviteComponent visible={inviteVisible} hide={toggleInvite} projectId={id} inviteSent={inviteSent}/>
        <ManageUsers visible={manageUsersVisible} hide={toggleManageUsers} project={project} displayToast={displayToast} />
        <div className="flex-row">
            <Tooltip target=".favorite-wrapper" mouseTrack mouseTrackLeft={10} />
            <div className="favorite-wrapper pl20" onClick={() => toggleProjectFavorite()} data-pr-tooltip="Toggle Favorite">
                {project.favorite ? 
                <FontAwesomeIcon icon={solidStar} className="favorite" />
                :
                <FontAwesomeIcon icon={faStar} className="non-favorite" />
                }
            </div>
            {project.role === 'OWNER' ?
            <>
            <Tooltip target=".options-wrapper" mouseTrack mouseTrackLeft={10} />
            <div className="options-wrapper pl20" onClick={(e) => op.current.toggle(e)} data-pr-tooltip="Project Options">
                <FontAwesomeIcon icon={faEllipsisH} />
            </div>
            <OverlayPanel ref={op} id="options-overlay" className="options-overlaypane">
                <div className="flex-col start">
                    <Button label="Invite User" className="p-button-text p-button-plain" onClick={toggleInvite} />
                    <Button label="Manage Users" className="p-button-text p-button-plain" onClick={toggleManageUsers} />
                    <Button label="Edit" className="p-button-text p-button-plain" onClick={toggleEditProjectVisible}/>
                    <Button label="Delete" className="p-button-text p-button-plain" onClick={confirm} />
                </div>
            </OverlayPanel>
            </>
            :
            <></>
            }
        </div>
        </>
    )
}
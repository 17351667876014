import { useState, useEffect } from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Slider } from 'primereact/slider';
import TagDisplay from '../tags/TagDisplay';

export default function CreateEditFlag({ flag, visible, hide, displayToast, environmentId }) {
    const [ saving, setSaving ] = useState(false);
    // eslint-disable-next-line
    const [ formData, setFormData ] = useState({});
    const [ percentage, setPercentage ] = useState(null);
    const [ tagText, setTagText ] = useState();
    const [ tags, setTags ] = useState([]);
    const axiosInstance = useAxios();

    const handleTagSelect = (tag) => {
        setTags(tags.filter(t => t.text !== tag.text));
    }

    const handleTagBlur = () => {
        if (!tagText || tagText === '') return;
        const newTag = {
            text: tagText
        }
        setTags([...tags, newTag]);
        setTagText('');
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: flag ? flag.name : '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required';
            }
            return errors;
        },
        onSubmit: (data) => {
            setSaving(true);
            setFormData(data);
            data.environmentId = flag ? flag.environmentId : environmentId;
            data.id = flag ? flag.id : null;
            data.flagId = flag ? flag.flagId : null;
            data.percentage = percentage ? percentage : 0;
            data.tags = tags;
            axiosInstance.current.post("/flags", data).then((response) => {
                if (flag) {
                    displayToast({severity: 'success', summary: 'Edited!', detail: 'Edit successful!'});
                } else {
                    displayToast({severity: 'success', summary: 'Created!', detail: 'Flag created successfully!'});
                }
            }).catch((err) => {
                console.log(err);
                displayToast({severity: 'error', summary: 'Error!', detail: 'Error encountered!'});
            }).finally(() => {
                setSaving(false);
                data.name = '';
                hide();
            })
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    }

    useEffect(() => {
        setPercentage(flag ? flag.percentage : 100);
        setTags(flag ? flag.tags : []);
    }, [flag])

    return (
        <div className="form" style={{ maxWidth: 400 }}>
            <Dialog header={flag ? "Edit Flag" : "Create New Flag"} visible={visible} onHide={hide}>
                <div className="flex-col center dialog">
                    <div className="card">
                        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                            <div className="field m20">
                                <span className="p-float-label">
                                    <InputText id="name" name="name" maxLength={100} value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name *</label>
                                </span>
                                {getFormErrorMessage('name')}
                            </div>
                            <small className="p-float-label m20">Audience {percentage}%</small>
                            <div className="field m20">
                                <InputNumber id="percentage_text" name="percentage_text" min={0} max={100} allowEmpty={false} value={percentage} onChange={(e) => setPercentage(e.value > 100 ? 100 : e.value)} />
                                <Slider className="mt20" id="percentage" name="percentage" value={percentage} onChange={(e) => setPercentage(e.value)} />
                            </div>
                            <div className="field m20">
                                <span className="p-float-label">
                                    <InputText id="tag" name="tag" maxLength={20} value={tagText} onChange={(e) => setTagText(e.target.value)} placeholder="New Tag" onBlur={handleTagBlur}/>
                                </span>
                            </div>
                            <div className="field m20">
                                <TagDisplay tags={tags} removable={true} selectTag={handleTagSelect} />
                            </div>
                            <div className="flex-row center mt20">
                                {flag ?
                                <Button style={{ margin: 20 }}type="submit" label="Save Flag" icon="pi pi-save" loading={saving} />
                                :
                                <Button style={{ margin: 20 }}type="submit" label="Create Flag" icon="pi pi-save" loading={saving} />
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
import { useState } from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

export default function CreateEditEnvironment({ visible, hide, displayToast, projectId, environment}) {
    const [ saving, setSaving ] = useState(false);
    // eslint-disable-next-line
    const [ formData, setFormData ] = useState({});
    const axiosInstance = useAxios();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: environment ? environment.name : '',
            projectId: projectId
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required';
            }
            return errors;
        },
        onSubmit: (data) => {
            setSaving(true);
            setFormData(data);
            data.projectId = environment ? environment.projectId : projectId;
            data.environmentId = environment ? environment.environmentId : null;
            axiosInstance.current.post("/projects/environment", data).then((response) => {
                if (environment) {
                    displayToast({ severity: 'success', summary: 'Saved!', detail: 'Environment saved successfully!'});
                } else {
                    displayToast({ severity: 'success', summary: 'Created!', detail: 'Environment created successfully!'});
                }
            }).catch((err) => {
                console.log(err);
                displayToast({ severity: 'error', summary: 'Error!', detail: 'Error encountered!'});
            }).finally(() => {
                setSaving(false);
                data.name = '';
                hide();
            })
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    }

    return (
        <div className="form">
            <Dialog header={environment ? "Edit Environment" : "Create New Environment"} visible={visible} onHide={hide}>
                <div className="flex-col center">
                    <div className="card">
                        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
                            <div className="field m20">
                                <span className="p-float-label">
                                    <InputText id="name" name="name" maxLength={100} value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name *</label>
                                </span>
                                {getFormErrorMessage('name')}
                            </div>

                            <div className="flex-row center mt20">
                                <Button style={{ margin: 20 }}type="submit" label={environment ? "Save Environment" : "Create Environment"} icon="pi pi-save" loading={saving} />
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
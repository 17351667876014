export default function Version() {
    return (
        <div className="flex-col wrap">
            <div>
                Version 1.0.2
            </div>
            <div>
                <span className="audit-details">(07/25/2024)</span>
            </div>
            <ul>
                <li>Allow for users to deactivate flags so they don't show up on the project page</li>
            </ul>
            <div>
                Version 1.0.1
            </div>
            <div>
                <span className="audit-details">(02/14/2024)</span>
            </div>
            <ul>
                <li>Page for flag details to show all environments</li>
            </ul>
            <div>
                Version 1.0.0
            </div>
            <div>
                <span className="audit-details">(04/03/2023)</span>
            </div>
            <ul>
                <li>Initial Release</li>
            </ul>
        </div>
    )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'primereact/card';
import { faArrowCircleRight } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function ProjectSummary({ project }) {
    const navigate = useNavigate();

    const header = (
        <div className="flex-row end" style={{ margin: '10px 20px 0 20px'}}>
            <div className="pointer" onClick={() => navigate('/projects/' + project.projectId)} ><span>View Details </span><FontAwesomeIcon icon={faArrowCircleRight}/></div>
        </div>
    )
    
    return (
        <Card style={{ margin: 10, width: '550px' }} title={project.name} subTitle={project.projectId.substring(0,8)} header={header}>
            <div className="tertiary-title mt10">
                Environments
            </div>
            {project.environments.map(e => (
                <div key={e.environmentId}>{e.name} - {e.flags.filter(f => f.enabled).length} flag{e.flags.filter(f => f.enabled).length === 1 ? '' : 's'} enabled</div>
            ))}
        </Card>
    )
}
import * as React from 'react';
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "react-bootstrap";
import { Toast } from 'primereact/toast';
import CreateEditProject from "../../components/projects/CreateEditProject";
import ProjectSummary from '../../components/projects/ProjectSummary';
import { useAxios } from '../../utils/hooks.ts';
import ProgressWrapper from '../../components/ProgressWrapper';
import { InputText } from 'primereact/inputtext';
import MessageDisplay from '../../components/messages/MessageDisplay';

export default function Projects() {
    const [ createVisible, setCreateVisible ] = React.useState(false);
    const [ projects, setProjects ] = React.useState([]);
    const [ projectData, setProjectData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(true);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ filterText, setFilterText ] = React.useState("");
    const toast = React.useRef(null);
    const axiosInstance = useAxios();

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleVisible = () => {
        setCreateVisible(!createVisible);
    }

    const displayToast = (msg) => {
        toast.current.show(msg);
        flipFetchFlag();
    }

    React.useEffect(() => {
        if (!filterText) {
            setProjects(projectData);
        } else {
            setProjects(projectData.filter(p => p.name.includes(filterText)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterText])

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/projects/all").then((response) => {
            if (response.data) {
                setProjects(response.data);
                setProjectData(response.data);
            }
        })
        .catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Unable to retrieve projects'});
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);

    return (
        <div>
            {loading ?
            <ProgressWrapper />
            :
            <Container>
                <Toast ref={toast} />
                <MessageDisplay />
                <CreateEditProject visible={createVisible} hide={toggleVisible} displayToast={displayToast} />
                <div>
                    <div className="title pt20">
                        Projects
                    </div>
                    <div className="pt20">
                        <InputText value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Filter By Name" />
                    </div>
                    <div className="secondary-title pt20 pointer" onClick={toggleVisible}>
                        <FontAwesomeIcon icon={faPlusCircle} /><span className="pl10">Create New Project</span>
                    </div>
                    {loading ?
                    <ProgressWrapper />
                    :
                    <>
                        <div className="secondary-title mt10">Favorite Projects</div>
                        <div className="flex-row wrap">
                            {projects.filter(p => p.favorite).length === 0 && <div style={{ color: 'var(--primary-color)', fontSize: 20 }}>No favorites yet!</div>}
                            {projects.filter(p => p.favorite).map(p => (
                                <ProjectSummary key={p.projectId} project={p} />
                            ))}
                        </div>
                        <div className="secondary-title mt10">Other Projects</div>
                        <div className="flex-row wrap">
                            {projects.filter(p => !p.favorite).map(p => (
                                <ProjectSummary key={p.projectId} project={p} />
                            ))}
                        </div>
                    </>
                    }
                </div>
            </Container>
            }
        </div>
    )
}
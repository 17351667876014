import { Route, Routes } from "react-router-dom";
import Analytics from "../../pages/analytics/Analytics";
import Projects from "../../pages/projects/Projects";
import Join from "../../pages/join/JoinPage";
import ProjectDetails from "../../pages/projects/ProjectDetails";
import Settings from "../../pages/settings/Settings";
import FlagAnalytics from "../../pages/flags/FlagAnalytics";
import About from "../../pages/about/About";
import Documentation from "../../pages/documentation/Documentation";
import FlagDetails from "../../pages/flags/FlagDetailsPage";
import Flags from "../../pages/flags/Flags";

export default function Content() {
    return(
        <div>
            <div id="main">
                <Routes>
                    <Route exact path="/" element={<Projects />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/projects/:id" element={<ProjectDetails />} />
                    <Route path="/projects/:id/e/:envId/f/:flagId" element={<ProjectDetails />} />
                    <Route path="/flags" element={<Flags />} />
                    <Route path="/flags/:id" element={<FlagDetails />} />
                    <Route path="/analytics" element={<Analytics />} />
                    <Route path="/analytics/:id" element={<FlagAnalytics />} />
                    <Route path="/documentation" element={<Documentation />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/join/:id" element={<Join />} />
                </Routes>
            </div>
        </div>
    )
}
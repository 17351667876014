export default function SLA() {
    return (
        <div>
            <div className="secondary-title">Featurely Service Level Agreement Terms</div>
    <p>This Featurely Service Level Agreement Terms (“SLA”) communicates the Featurely SLA’s available for the production instance of Customer’s Featurely software as a service purchased under an Order Form (“Featurely Service”) and applicable mutually agreed upon master agreement governing the Customers use of the Featurely Service (“Agreement”). Capitalized terms used but not defined herein shall have the respective meanings given to them in the Agreement. Featurely reserves the right to modify this policy at Featurely’s sole discretion, upon reasonable prior notice.</p>

    <h5>1. Standard Terms.</h5>
    <p>a. “Uptime Commitment” During each calendar month of the Term, Featurely warrants at least a) 99.9% System Availability for the Featurely Service for Enterprise Support Customers, and b) 99.99% System Availability for the Featurely Service for Premium Support Customers.</p>

    <p>b. “System Availability” for the Featurely SaaS Service is calculated per calendar month by:</p>

    <p>i. Dividing (x) the total minutes during which the user interface of the Featurely Service in a Customer production instance is available in the month minus the total minutes of scheduled maintenance in the month, by (y) the total minutes in the month minus the total minutes of scheduled maintenance in the month; and

    ii. Multiplying the result by 100.

    iii. For purposes of calculating System Availability, only the unavailability of the Featurely SaaS Service exceeding 30 seconds will apply. </p>

    <p>c. Featurely reserves the right to take the Featurely Service offline for scheduled maintenance for which Customer has been provided reasonable notice.</p>

    <p>d. Featurely reserves the right to change its maintenance window upon prior notice to Customer.</p>

    <h5>2. Exclusions.</h5>
    <p>Customer shall not have any remedies under this SLA to the extent any SLA Claim is due to unavailability of the Featurely SaaS Service resulting from the following (“Exclusions”):</p>

    <p>a. issues associated with the Customer’s computing devices, local area networks, or internet service provider connections,</p>

    <p>b. services, circumstances or events beyond the reasonable control of Featurely, including, without limitation, any Force Majeure events, the performance and/or availability of local ISPs employed by Customer, or any network beyond the demarcation or control of Featurely.</p>

    <p>c. use of the Featurely Service outside the scope described in the Agreement,</p>

    <p>d. inability to provide the Featurely Service due to acts or omissions of Customer or any User, </p>

    <p>e. planned or emergency maintenance, </p>

    <p>f. issues caused by Customer after Featurely advised Customer to modify Customer’s use of the Featurely Service, if Customer did not modify its use as advised,</p>

    <p>g. issues during beta and trial use of the Featurely Service</p>

    <p>h. issues that resulted from the use of third-party hardware or software, in which the third-party hardware or software caused the service degradation.</p>

    <h5>3. SLA Claims.</h5>
    <p>Customer must notify Featurely e-mailing admin@new-labs.co. For customer to be eligible for consideration of a Service Credit due to an SLA Claim, the request must be received by Featurely within 5 days of the date the SLA incident incurred, and must include:</p>

    <p>a. The words “SLA credit request” in the subject line of the email.</p>

    <p>b. Describe the incident in detail;</p>

    <p>c. The dates and times for each incident claimed, with such accuracy as can reasonably be determined; and</p>

    <p>d. Reference Featurely Status Page recording downtime; or</p>

    <p>e. Monitoring logs and supporting evidence corroborating Customer’s claimed outage, with any confidential or personally identifying information removed. Featurely shall provide monitoring logs to Customer to support credit requests as reasonably requested.</p>

    <p>Featurely will use log files, database records, audit logs and any other information available to validate an SLA Claim and make a good faith judgment on the applicability of this SLA to such SLA Claim. If the System Availability is confirmed by Featurely to fall outside of the service commitment range for the incidents described in the SLA Claim (a “Valid SLA Claim”), the remedies described in Section 4 shall apply. Failure to provide the information required above will disqualify Customer from receiving a Service Credit.</p>

    <p>In the event an SLA Claim is denied, Featurely shall make the information used to validate such SLA Claim available to Customer for thirty (30) Business Days from SLA Claim denial, for auditing by Customer at Customer’s request.  </p>

    <h5>4. Service Commitments.</h5>
    <p>a. Enterprise Support: If (i) Featurely fails to meet its Uptime Commitment in any two (2) consecutive month periods; or (ii) the total System Availability falls below 75% availability in any single calendar month (excluding downtime due to planned downtime or other Exclusion); provided that Customer has fulfilled all of its obligations under the Agreement, then upon Featurely’s receipt of a Valid SLA Claim, Customer shall have the right terminate the Agreement upon five (5) days’ written notice to Featurely, and Featurely shall promptly provide to Customer a refund of any unused, pre-paid fees pro-rated from the date of termination.</p>

    <p>b. Premium Support: Customers who purchase Premium Support shall receive a Service Credit if Featurely fails to meet the Uptime Commitment in accordance with this Section and the table below. If (i) Featurely fails to meet its Uptime Commitment in any two (2) consecutive month period, or (ii) the System Availability falls below 75% availability in any single calendar month (excluding downtime due to planned downtime or other exclusion), and provided that Customer has fulfilled all of its obligations under the Agreement, then upon Featurely Valid SLA Claim, Featurely will issue a Service Credit in Customer’s next invoice, calculated in accordance with the table below. “Service Credit” represents a percentage of the monthly fee associated with the affected Featurely SaaS Service.  In any given calendar month, Customer shall in no event be entitled to receive a Service Credit that exceeds 50% of its monthly fee for the affected Featurely Service.</p>
        </div>
    )
}
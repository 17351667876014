import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faLink } from '@fortawesome/pro-light-svg-icons';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import ProgressWrapper from '../../components/ProgressWrapper.js';
import { useAxios } from '../../utils/hooks.ts';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { confirmPopup } from 'primereact/confirmpopup';
import FlagDetails from '../../components/flags/FlagDetails.js';
import CreateEditFlag from '../../components/flags/CreateEditFlag.js';

export default function FlagDetailsPage() {
    const [ loading, setLoading ] = React.useState(true);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ flags, setFlags ] = React.useState([]);
    const [ createVisible, setCreateVisible ] = React.useState(false);
    const [ detailsVisible, setDetailsVisible ] = React.useState(false);
    const [ selectedFlag, setSelectedFlag ] = React.useState(null);
    const { id } = useParams();
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleVisible = () => {
        setCreateVisible(!createVisible);
    }

    const toggleDetails = () => {
        setDetailsVisible(!detailsVisible);
    }

    const displayToast = (msg) => {
        toast.current.show(msg);
        flipFetchFlag();
    }

    const toggleFlag = (flag) => {
        const data = {
            id: flag.id,
            flagId: flag.flagId,
            environmentId: flag.environmentId,
            enabled: !flag.enabled
        }
        axiosInstance.current.put("/flags", data).then(() => {
            flag.enabled = data.enabled;
            flipFetchFlag();
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const toggleActive = (flag) => {
        const data = {
            id: flag.id,
            flagId: flag.flagId,
            environmentId: flag.environmentId,
            enabled: flag.enabled,
            active: !flag.active
        }
        axiosInstance.current.put("/flags", data).then(() => {
            flag.enabled = data.enabled;
            flipFetchFlag();
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const editFlag = (flag) => {
        setSelectedFlag(flag);
        setCreateVisible(true);
    }

    const enabledBody = (rowData) => {
        return <InputSwitch checked={rowData.enabled} onChange={() => toggleFlag(rowData)} disabled={flags[0]?.role === 'USER'} />
    }

    const activeBody = (rowData) => {
        return <InputSwitch checked={rowData.active} onChange={() => toggleActive(rowData)} disabled={flags[0]?.role === 'USER'} />
    }

    const viewBody = (rowData) => {
        return <Button label="View Details" className="p-button-text" icon="pi pi-angle-right" onClick={() => {setSelectedFlag(rowData); setDetailsVisible(true);}} disabled={flags[0]?.role === 'USER'}/>
    }

    const editBody = (rowData) => {
        return <Button className="p-button-outlined" icon="pi pi-pencil" onClick={() => editFlag(rowData)} disabled={flags[0]?.role === 'USER'} />
    }

    const deleteBody = (rowData) => {
        return <Button className="p-button-outlined p-button-danger" icon="pi pi-trash" onClick={(e) => confirmDeleteFlag(rowData, e)} disabled={flags[0]?.role === 'USER'} />
    }

    const reject = () => {
    };

	const confirmDeleteFlag = (flag, e) => {
		confirmPopup({
			target: e.currentTarget,
			message: "Are you sure you want to delete this flag?",
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: () => deleteFlag(flag),
			reject
		})
	}

    const deleteFlag = (flag) => {
		axiosInstance.current.delete("/flags/" + flag.flagId).then((response) => {
            flipFetchFlag();
		})
		.catch((err) => {
            console.log(err);
			toast.current.show({
				severity: 'error',
				summary: 'Unable to delete flag!',
				detail: err.response ? err.response.data : ''
			});
		})
	}

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/flags/flag-id/" + id).then((response) => {
            setFlags(response.data);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag]);

    return (
        <div>
            {loading ?
            <ProgressWrapper />
            :
            <Container>
                <Toast />
                <CreateEditFlag flag={selectedFlag} visible={createVisible} hide={toggleVisible} displayToast={displayToast} environmentId={selectedFlag?.environmentId} />
                <FlagDetails visible={detailsVisible} hide={toggleDetails} flag={selectedFlag} flipFetchFlag={flipFetchFlag} apiKey={""} toggleFlag={toggleFlag} />
                <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
                    <li className="breadcrumb-item" onClick={() => navigate("/projects")}>Projects</li>
                    <li className="breadcrumb-item" onClick={() => navigate("/projects/" + flags[0]?.projectId)}>{flags[0]?.projectName}</li>
                    <li className="breadcrumb-item active">{flags[0]?.name}</li>
                </ol>
                </nav>
                <div className="flex-row title">
                    <div>{flags[0].name}</div>
                </div>
                <div className="secondary-title pt20 pointer" onClick={() => navigate("/projects/" + flags[0]?.projectId)}>
                    <FontAwesomeIcon icon={faLink} /> View Project
                </div>
                <div className="mt20">
                    <div className="secondary-title">
                        <div>Flag ID</div>
                    </div>
                    <div>
                        {id}
                    </div>
                </div>
                <div className="mt20">
                    <div className="secondary-title">
                        <div>Environments</div>
                    </div>
                    <DataTable
                        value={flags}
                        rows={5}
                        paginator
                        responsiveLayout="stack"
                        size="small"
                        dataKey="id"
                    >
                        <Column field="environmentName" header="Environment"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column body={enabledBody} header="Enabled"></Column>
                        <Column body={activeBody} header="Viewable"></Column>
                        <Column body={viewBody}></Column>
                        <Column body={editBody}></Column>
                        <Column body={deleteBody}></Column>
                    </DataTable>
                </div>
            </Container>
            }
        </div>
    )
}
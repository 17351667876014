import { Container } from 'react-bootstrap';
import { TabView, TabPanel } from 'primereact/tabview';

export default function Documentation() {
    return (
        <div>
            <Container>
                <div>
                    <div className="title pt20">
                        How-To Guides
                    </div>
                    <div className="pt20">
                        <TabView className="tabview-header-icon">
                            <TabPanel header="Projects" leftIcon="pi pi-fw pi-file">
                                <div className="secondary-title text-primary">Projects</div>
                                <p>Your applications will need to be deployed to various environments such as locally for development,
                                    a production-like testing environment, and a production environment for your customers to be able
                                    to interact with. When you create a new Project in Featurely, that new project is assigned an API key
                                    that will be used with each request to get the status of your feature flags. This key is a unique identifier
                                    for your project.
                                </p>
                                <div className="secondary-title text-primary">Favorites</div>
                                <p>Projects that you select as being favorites will be displayed at the top of the Projects page; additional projects
                                    are listed below the favorites in alphabetical order.
                                </p>
                                <div className="secondary-title text-primary">Users</div>
                                <p>The creator of the project is established as the <b>Owner</b> of the project and has full admin access to the project. The Owner
                                    can create and delete environments, create and delete flags, enable and disable flags, and invite additional users
                                    to the project.
                                </p>
                                <p><b>Admin</b> Users have similar privilegs to Owner users with the exception of managing users.</p>
                                <p><b>Users</b> are only able to view environments within a project and the details of the flags.</p>
                            </TabPanel>
                            <TabPanel header="Environments" leftIcon="pi pi-fw pi-file">
                                <div className="secondary-title text-primary">Environments</div>
                                <p>Applications deployed to different environments often need to function differently depending on the progress of features
                                    that have been developed. Features may need to be enabled locally for development but disabled in the production environment
                                    and hidden from users. Environments within a project allow you to create flags for different environments and toggle features
                                    separately as an application progresses through your software development lifecycle.
                                </p>
                                <div className="secondary-title text-primary">Creating a New Environment</div>
                                <p>Creating a new environment in a project will create a unique identifier for that environment within that project. That unique identifier
                                    can be used to get the status of all flags within an environment. Environments can have the same name but will have different identifiers. 
                                </p>
                                <div className="secondary-title text-primary">Deleting an Environment</div>
                                <p>Deleting an environment removes the environment from the project and deletes all flags for that environment. <b>Warning! This is not reversible.</b></p>
                                <div className="secondary-title text-primary">Getting All Flags for Environment</div>
                                <p>The environment's unique identifiers along with the project's API key can be used to get the status of every flag for a given environment. This
                                    is done through a REST API request and the response is a list of all of the flags including the flag name, identifier, and enabled status. 
                                    The URL for getting the status of all flags in an environment is:
                                </p>
                                <div className="code-block">
                                    <pre>https://www.featurerollout.com/api/flags/status/environment/[environment-identifier]</pre>
                                </div>
                                <p>The project's API key must be included as a header in the request as well.</p>
                                <div className="code-block">
                                    <pre>{"'api-key: [project-api-key]'"}</pre>
                                </div>
                                <p>An example response from the API is:</p>
                                <div className="code-block" style={{ whiteSpace: "pre-wrap"}}>
                                    <pre>{'[{\n\t"name": "Documentation Flag",\n\t"flagId": "78bb019d-e20b-4ecf-b4d8-c152512218c5",\n\t"enabled": true\n}]'}
                                    </pre>
                                </div>
                                <div className="secondary-title text-primary">Copying Flags</div>
                                <p>Using the flag details, you can copy a flag to the other environments in a project. The flag will retain its name and identifier; however, each new
                                    flag in the other environments will behave separately and can be toggled individually.
                                </p>
                            </TabPanel>
                            <TabPanel header="Flags" leftIcon="pi pi-fw pi-flag">
                                <div className="secondary-title text-primary">Flags</div>
                                <p>Flags can be used anywhere in your application where you want to dynamically execute code given certain parameters. Flags are identified by their environment
                                    and their identifier. Furthermore, additional information can be provided by your application to determine the status of a flag.
                                </p>
                                <div className="secondary-title text-primary">Creating a Flag</div>
                                <p>When creating a flag, you provide it with a name and the identifier is generated automatically. The <b>Audience</b> parameter is used to determine how much
                                    traffic should see an enabled status. Lastly, <b>Tags</b> are used to determine if a specific request should be enabled or not.
                                </p>
                                <div className="secondary-title text-primary">Audience</div>
                                <p>The audience parameter is used to limit the amount of traffic that will receive an enabled flag. <i>Note: If the flag is disabled, every request is disabled.</i> For 
                                    example, if the audience parameter is set to 50%, then every other request will have an enabled flag. If the audience parameter is 10%, then one out of every
                                    ten requests will have an enabled flag.
                                </p>
                                <div className="secondary-title text-primary">Tags</div>
                                <p>Tags are used to determine if a specific request should receive an enabled flag. <i>Note: If the flag is disabled, every request is disabled.</i> For example,
                                    if the flag has a Tag attribute of "test" then any request with the tag header of "test" will receive an enabled status. If the flag has tags associated with it
                                    and no tags are provided in the header, then the request will result in a disabled flag.
                                </p>
                                <div className="secondary-title text-primary">Getting Flag Status</div>
                                <p>The flag's identifier along with the environment identifer and project API key can be used to get the status of individual flags. This is done through a REST API
                                    request and the response is the status of flag including the flag name, identifier, and enabled status. The URL for getting the status of a flag is:
                                </p>
                                <div className="code-block">
                                    <pre>https://www.featurerollout.com/api/flags/status/environment/[environment-id]/flag/[flag-id]</pre>
                                </div>
                                <p>The project's API key must be included as a header in the request as well. The tag header can provide tags as needed.</p>
                                <div className="code-block">
                                    <pre>{"'api-key: [project-api-key]'"}</pre>
                                </div>
                                <p>An example response from the API is:</p>
                                <div className="code-block" style={{ whiteSpace: "pre-wrap"}}>
                                    <pre>{'{\n\t"name": "Documentation Flag",\n\t"flagId": "78bb019d-e20b-4ecf-b4d8-c152512218c5",\n\t"enabled": true\n}'}
                                    </pre>
                                </div>
                                <div className="secondary-title text-primary">Using Tags to Get Flag Status</div>
                                <p>If a flag has tags set on it, then a request parameter must be used to get the status of the flag. The request parameter for flags is a comma-separated
                                    list of strings that are used to test for when determining if a flag is enabled or not. For example, a flag may be required for a specific customer, Customer A. Any 
                                    request for this customer will need to have a request parameter with the appropriate tag to get the status of the flag. Any unmatched tags will result in a status
                                    of disabled. This flag requires a tag of <u>CustomerA</u> in order to be enabled for that request. A tag of <u>CustomerB</u> would result in a disabled flag.
                                </p>
                                <div className="code-block">
                                    <pre>https://www.featurerollout.com/api/flags/status/environment/[environment-id]/flag/[flag-id]?tags=CustomerA</pre>
                                </div>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </Container>
        </div>
    )
}
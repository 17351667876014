import * as React from 'react';
import { Dropdown } from "primereact/dropdown";
import { Button } from 'primereact/button';
import { useKeycloak } from '@react-keycloak/web';

const roles = [
    {name: 'OWNER', role: 'OWNER'},
    {name: 'ADMIN', role: 'ADMIN'},
    {name: 'USER', role: 'USER'}
]

export default function User({ user, removeUser }) {
    const [ selectedRole, setSelectedRole ] = React.useState(user.role);
    const { keycloak } = useKeycloak();

    const selectRole = (role) => {
        setSelectedRole(role);
        user.role = role;
    }

    return (
        <div className="flex-row between m20" style={{ minWidth: 400 }} key={user.projectUserId}>
            <div className="ml10">{user.name}</div>
            <div>
                <Dropdown style={{ width: 150 }} optionLabel="name" optionValue="role" value={selectedRole} options={roles} onChange={(e) => selectRole(e.value)} disabled={keycloak.tokenParsed.sub === user.userId}/>
                <Button className="p-button-outlined p-button-danger" style={{ marginLeft: 10 }} icon={`${user.userId ? "pi pi-trash" : "pi pi-check"}`} onClick={() => removeUser(user)} disabled={keycloak.tokenParsed.sub === user.userId} />
            </div>
        </div>
    )
}
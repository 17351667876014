import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { AutoComplete } from 'primereact/autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';

export default function Searchbar() {
    const { keycloak } = useKeycloak();
    const [ suggestions, setSuggestions ] = React.useState();
    const [ selectedFlag, setSelectedFlag ] = React.useState();
    const axiosInstance = useAxios();
    const navigate = useNavigate();

    const selectFlag = (event) => {
        if (event.flagId) {
            setSelectedFlag(null);
            navigate("/flags/" + event.flagId);
        }
    }

    const search = (event) => {
        axiosInstance.current.get("/flags/search/" + event.query.trim()).then((response) => {
            if (response.data) {
                setSuggestions(response.data);
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const itemTemplate = (item) => {
        return (
            <div className="flex-col">
                <div className="secondary-title">{item.name}</div>
                <div className="audit-details">Project: {item.projectName}</div>
                <div className="audit-details">Environment: {item.environmentName}</div>
                <div className="audit-details">ID: {item.flagId}</div>
            </div>
        )
    }

    return (
        <div id="searchbar">
            <div className="hide-on-mobile flex-row center align-center" style={{ marginLeft: 250}}>
                <span style={{ color: 'white', fontSize: 25, paddingRight: 10 }} ><FontAwesomeIcon icon={faSearch} /></span>
                <AutoComplete placeholder="Search by Id or Name" value={selectedFlag} suggestions={suggestions} completeMethod={search} field="flagId" onChange={(e) => {setSelectedFlag(e.value); selectFlag(e.value);}} itemTemplate={itemTemplate} />
            </div>
            <div className="light-text" style={{ fontSize: 25, marginRight: 20 }}>
                {keycloak.tokenParsed.email}
            </div>
        </div>
    )
}
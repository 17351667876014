import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faLink } from '@fortawesome/pro-light-svg-icons';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import ProgressWrapper from '../../components/ProgressWrapper';
import { useAxios } from '../../utils/hooks.ts';
import { Toast } from 'primereact/toast';
import FlagDetails from '../../components/flags/FlagDetails';
import { Button } from 'primereact/button';
import { parseISO, getYear, getMonth, getDate, getHours, getMinutes } from 'date-fns';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';

const intervalItems = [5, 10, 15, 30, 45, 60, 90];

export default function FlagAnalytics() {
    const [ loading, setLoading ] = React.useState(true);
    const [ flag, setFlag ] = React.useState();
    const [ detailsVisible, setDetailsVisible ] = React.useState(false);
    const [ chartData, setChartData ] = React.useState({});
    const [ chartInterval, setChartInterval ] = React.useState(15);
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const { id } = useParams();

    const toggleDetailsVisible = () => {
        setDetailsVisible(!detailsVisible);
    }

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.current.get("/flags/" + id).then((response) => {
            const flag = response.data;
            setFlag(flag);
            let requestMap = new Map();
            flag.requestList.forEach(r => {
                const isoTime = parseISO(r.requestTime);
                const y = getYear(isoTime);
                const m = getMonth(isoTime);
                const d = getDate(isoTime);
                const h = getHours(isoTime);
                const mi = getMinutes(isoTime);
                const key = 'y'+y+'m'+m+'d'+d+'h'+h+'mi'+mi;
                if (requestMap.get(key)) {
                    let count = requestMap.get(key);
                    requestMap.set(key, ++count);
                } else {
                    requestMap.set(key, 1);
                }
            })
            const date = new Date();
            const cy = getYear(date);
            const cm = getMonth(date);
            const cd = getDate(date);
            let ch = getHours(date);
            let cmi = getMinutes(date);
            let minutePointer = cmi;
            let minutes = [];
            let reqCount = [];
            for (var i = chartInterval; i > 0; i--) {
                const key = 'y'+cy+'m'+cm+'d'+cd+'h'+ch+'mi'+(minutePointer);
                if (requestMap.get(key)) {
                    reqCount = [requestMap.get(key), ...reqCount];
                } else {
                    reqCount = [0, ...reqCount];
                }
                if (minutePointer === -1) {
                    minutePointer = 59;
                    ch--;
                }
                if (ch === -1) {
                    ch = 23;
                }
                minutes = [""+ch+":"+(minutePointer < 10 ? '0'+minutePointer : minutePointer), ...minutes];
                minutePointer--;
            }
            const chartData = {
                labels: minutes,
                datasets: [{
                    label: 'Requests over last ' + chartInterval + ' minutes',
                    data: reqCount,
                    fill: false,
                    borderColor: '#42A5F5',
                    tension: .4
                }]
            }
            setChartData(chartData);
        })
        .finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartInterval])

    return (
        <div>
            {loading ?
            <ProgressWrapper />
            :
            <Container>
                <Toast />
                <FlagDetails flag={flag} visible={detailsVisible} hide={toggleDetailsVisible} />
                <div style={{ paddingTop: 50 }}></div>
                <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
                    <li className="breadcrumb-item" onClick={() => navigate("/analytics")}>Analytics</li>
                    <li className="breadcrumb-item active">{flag.name}</li>
                </ol>
                </nav>
                <div className="flex-row title">
                    <div>{flag.name}</div><div><Button className="p-button-text" label="View Details" icon="pi pi-angle-right" onClick={toggleDetailsVisible} /></div>
                </div>
                <div className="secondary-title pointer" onClick={() => navigate("/projects/"+flag.projectId)}>
                    <FontAwesomeIcon icon={faLink} /> View Project
                </div>
                <div className="mt20">
                    <div className="secondary-title">
                        <div>Flag ID</div>
                    </div>
                    <div>
                        {flag.flagId}
                    </div>
                </div>
                <div className="secondary-title">
                    Request History
                </div>
                <div className="pt20">
                    <Chart data={chartData} type="line" style={{ width: '50%'}} options={{ scales: { y: { beginAtZero: true } } }}/>
                    <Dropdown options={intervalItems} value={chartInterval} onChange={(e) => setChartInterval(e.value)} /> Minutes
                </div>
            </Container>
            }
        </div>
    )
}
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { BrowserRouter } from 'react-router-dom';
import Main from './pages/main/Main';
import keycloak from '../public/keycloak';

function App() {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}

export default App;

import * as React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Container } from "react-bootstrap";
import Version from '../../components/about/Version';
import Future from '../../components/about/Future';
import SLA from '../../components/about/SLA';

function About() {
    return (
        <Container>
            <div className="title mt20">
                About Featurely
            </div>
            <div style={{ marginTop: 10 }}>
                <div className="audit-details">New Labs System Status</div>
                <statuspage-widget src="https://newlabs.statuspage.io"></statuspage-widget>
            </div>
            <TabView className="tabview-header-icon">
                <TabPanel header="Dev Notes" leftIcon="pi pi-fw pi-file">
                    <Version />
                </TabPanel>
                <TabPanel header="Future Features" leftIcon="pi pi-fw pi-file">
                    <Future />
                </TabPanel>
                <TabPanel header="SLA" leftIcon="pi pi-fw pi-clock">
                    <SLA />
                </TabPanel>
            </TabView>
        </Container>
    )

}

export default About;
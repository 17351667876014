import * as React from 'react';
import { useAxios } from '../../utils/hooks.ts';
import { Dialog } from 'primereact/dialog';
import User from './User';
import { Button } from 'primereact/button';

export default function ManageUsers({ visible, hide, displayToast, project }) {
    const [ saving, setSaving ] = React.useState(false);
    const [ users, setUsers ] = React.useState([]);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const axiosInstance = useAxios();

    const removeUser = (user) => {
        user.userId = null;
        setFetchFlag(!fetchFlag);
    }

    const saveChanges = () => {
        setSaving(true);
        axiosInstance.current.post("/projects", project).then((response) => {
            displayToast({ severity: 'success', summary: 'Saved'});
        })
        .catch((err) => {
            console.log(err);
            displayToast({ severity: 'error', summary: 'Error'});
        })
        .finally(() => {
            setSaving(false);
            hide();
        })
    }

    React.useEffect(() => {
        setUsers(project.projectUsers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag])

    return (
        <Dialog header="Manage Users" visible={visible} onHide={hide}>
            <div className="flex-col center">
                <div className="card flex-col align-center">
                    {users.map((u, i) => (
                        <User key={i} user={u} removeUser={removeUser}/>
                    ))}
                    <Button style={{ margin: 20, width: '200px' }} label="Save Changes" icon="pi pi-save" onClick={saveChanges} loading={saving}/>
                </div>
            </div>
        </Dialog>
    )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Container } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import ProgressWrapper from '../../components/ProgressWrapper';
import { useAxios } from '../../utils/hooks.ts';
import { faClipboard, faHome, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import CreateEditEnvironment from '../../components/environments/CreateEditEnvironment';
import { Toast } from 'primereact/toast';
import EnvironmentSummary from '../../components/environments/EnvironmentSummary';
import CreateEditProject from '../../components/projects/CreateEditProject';
import ProjectOptions from '../../components/projects/ProjectOptions';
import FlagDetails from '../../components/flags/FlagDetails.js';

export default function ProjectDetails() {
    const [ project, setProject ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(true);
    const [ fetchFlag, setFetchFlag ] = React.useState(false);
    const [ createEditEnvironmentVisible, setCreateEditEnvironmentVisible ] = React.useState(false);
    const [ editProjectVisible, setEditProjectVisible ] = React.useState(false);
    const [ flagDetailsVisible, setFlagDetailsVisible ] = React.useState(false);
    const [ selectedFlag, setSelectedFlag ] = React.useState();
    const { id, envId, flagId } = useParams();
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);

    const toggleFlagDetails = () => {
        setFlagDetailsVisible(!flagDetailsVisible);
        navigate("/projects/" + id);
    }

    const toggleEditProjectVisible = () => {
        setEditProjectVisible(!editProjectVisible);
    }

    const flipFetchFlag = () => {
        setFetchFlag(!fetchFlag);
    }

    const toggleVisible = () => {
        setCreateEditEnvironmentVisible(!createEditEnvironmentVisible);
        flipFetchFlag();
    }

    const toggleFlag = (flag) => {
        const data = {
            id: flag.id,
            flagId: flag.flagId,
            environmentId: flag.environmentId,
            enabled: !flag.enabled
        }
        axiosInstance.current.put("/flags", data).then(() => {
            flag.enabled = data.enabled;
            flipFetchFlag();
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const displayToast = (msg) => {
        toast.current.show(msg);
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(project.apiKey);
        displayToast({ severity: 'info', detail: 'Copied!'});
    }

    React.useEffect(() => {
        axiosInstance.current.get("/projects/" + id).then((response) => {
            if (response.data) {
                response.data.environments.forEach(e => {
                    e.apiKey = response.data.apiKey
                    e.flags.forEach(f => {
                        f.shortId = f.flagId.substring(0,8)
                        f.audience = (f.requests * 100).toFixed(2);
                        f.projectId = id;

                        if (e.environmentId === envId && f.flagId === flagId) {
                            setSelectedFlag(f);
                            setFlagDetailsVisible(true);
                        }
                    })})
                setProject(response.data);
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFlag])

    return (
        <div>
            {loading ?
            <ProgressWrapper />
            :
            <Container>
                <Toast ref={toast} />
                <FlagDetails flag={selectedFlag} visible={flagDetailsVisible} hide={toggleFlagDetails} environmentId={envId} toggleFlag={toggleFlag} />
                <CreateEditProject visible={editProjectVisible} hide={toggleEditProjectVisible} displayToast={displayToast} project={project} />
                <CreateEditEnvironment visible={createEditEnvironmentVisible} hide={toggleVisible} displayToast={displayToast} projectId={project.projectId} envrionment={null} />
                <nav>
				<ol className="breadcrumb">
					<li className="breadcrumb-item" onClick={() => navigate("/")}><FontAwesomeIcon icon={faHome} /></li>
					<li className="breadcrumb-item" onClick={() => navigate("/projects")}>Projects</li>
                    <li className="breadcrumb-item active">{project.name}</li>
				</ol>
			    </nav>
                <div className="flex-row title mt20">
                    <div>{project.name}</div>
                    <ProjectOptions displayToast={displayToast} project={project} toggleEditProjectVisible={toggleEditProjectVisible} />
                </div>
                <div className="tertiary-title mt20">
                    API Key:
                </div>
                <div className="mt20">
                    {project.apiKey} <FontAwesomeIcon icon={faClipboard} style={{ marginLeft: 20, cursor: 'pointer' }} onClick={copyToClipboard}/>
                </div>
                <div>
                    <div className="secondary-title">
                        Environments
                    </div>
                    <div className="secondary-title pt20 pointer" onClick={toggleVisible}>
                        {project.role !== 'USER' && <><FontAwesomeIcon icon={faPlusCircle} /><span className="pl10">Create New Environment</span></>}
                    </div>
                    {project.environments.map(e => (
                        <EnvironmentSummary key={e.environmentId} role={project.role} environment={e} flipFetchFlag={flipFetchFlag} toast={toast}  />
                    ))}
                </div>
            </Container>
            }
        </div>
    )
}
import { useKeycloak } from "@react-keycloak/web";
import Content from "../../components/main/Content";
import Sidebar from "../../components/main/Sidebar";
import Searchbar from "../../components/main/Searchbar";


export default function Main() {
    const { initialized } = useKeycloak();

    return (
        <>
            {!initialized ?
                <div className="flex-col align-center" style={{ marginTop: 300 }}>
                    <div className="title">
                        Featurely
                    </div>
                    <div className="secondary-title">
                        by New Labs
                    </div>
                </div>
            :
                <div className="flex-col">
                    <Searchbar />
                    <Sidebar />
                    <Content />
                </div>
            }
        </>
    )
}
import * as React from 'react';
import { Container } from "react-bootstrap";
import FlagSummary from '../../components/flags/FlagSummary';
import ProgressWrapper from '../../components/ProgressWrapper';
import { useAxios } from '../../utils/hooks.ts';
import { InputText } from 'primereact/inputtext';

export default function Analytics() {
    const [ loading, setLoading ] = React.useState(true);
    const [ flags, setFlags ] = React.useState([]);
    const [ flagData, setFlagData ] = React.useState([]);
    const [ filterText, setFilterText ] = React.useState("");
    const axiosInstance = useAxios();

    React.useEffect(() => {
        if (!filterText) {
            setFlags(flagData);
        } else {
            setFlags(flagData.filter(f => f.name.includes(filterText)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterText])

    React.useEffect(() => {
        axiosInstance.current.get("/projects/all").then((response) => {
            const flags = [];
            response.data.forEach(p => p.environments.forEach(e => flags.push(...e.flags)));
            setFlagData(flags);
            setFlags(flags);
        })
        .finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {loading ?
            <ProgressWrapper />
            :
            <Container>
                <div>
                    <div className="title pt20">
                        Analytics
                    </div>
                </div>
                <div className="pt20">
                    <InputText value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Filter by Name"/>
                </div>
                <div>
                    <div className="secondary-title pt20">
                        Favorite Flags
                    </div>
                </div>
                <div className="flex-row wrap">
                {flags.filter(f => f.favorite).map(f => (
                    <FlagSummary key={f.flagId} flag={f} />
                ))}
                </div>
                <div>
                    <div className="secondary-title pt20">
                        Others
                    </div>
                </div>
                <div className="flex-row wrap">
                {flags.filter(f => !f.favorite).map(f => (
                    <FlagSummary key={f.flagId} flag={f} />
                ))}
                </div>
            </Container>
            }
        </div>
    )
}